.bgImg {
  background-size: cover;
  background-image: url(/assets/img/Metronome-BG.png);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.metronome {
  justify-content: center;
  text-align: center;
  max-width: 530px;
}

.metronomeContainer {
  z-index: 0;
  margin: auto;
  width: 370px;
  height: 700px;
  background-image: linear-gradient(180deg, #100714 0%, #1E1E53 100%);
  border: 5px solid #3415A9;
  box-shadow: 0 94px 250px -20px #FF7F4D;
  border-radius: 350px;
  transform: translateY(-100px);
}
  
.bpm-slider input {
  width: 375px;
  margin-top: 350px;
  transform: rotate(90deg);
}

.heroImg {
  z-index: 1;
  max-width: 310px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(90px);
}

.playButton {
  margin-top: 220px;
}

.sliderMain {
  background-image: url(./img/slider.svg);
  width: auto;
  height: auto;
}